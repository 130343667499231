@font-face {
  font-family: "Outfit";
  src:
    local("Outfit-regular"),
    url(./fonts/Outfit-Regular.woff2) format("woff2");
}

@media (min-width: 0px) and (max-width: 761px) {
  .box {
    padding-left: 24px;
    padding-right: 24px;
  }
  .boxhead {
  }
  .column1 {
    margin-top: 10px;
    width: auto;
    min-width: 200px;
    height: auto;
    float: left;
  }
  .column2 {
    margin-top: 10px;
    width: auto;
    min-width: 200px;
    height: auto;
    float: left;
  }
  .spacer {
    width: 0px;
  }
  .headlinetrinkspiele {
    padding-left: 27px;
    padding-right: 27px;
  }
  .grünebox {
    width: fit-content;
  }
  .Testimonialdiv {
    position: relative;
  }
}
@media (min-width: 761px) and (max-width: 1023px) {
  .box {
    padding-left: 40px;
    padding-right: 40px;
  }
  .boxhead {
    padding-left: 40px;
    padding-right: 40px;
  }
  .column1 {
    margin-top: 10px;
    width: 60%;
    min-width: 200px;
    height: auto;
    float: left;
  }
  .column2 {
    margin-top: 10px;
    width: 30%;
    min-width: 200px;
    height: auto;
    float: left;
  }
  .spacer {
    width: 50px;
    float: left;
  }
  .headerbox {
    height: 50px;
  }
  .headlinetrinkspiele {
    padding-left: 40px;
    padding-right: 40px;
  }
  .grünebox {
    width: 761px;
  }
  .Testimonialdiv {
    position: absolute;
  }
}
@media (min-width: 1024px) {
  .box {
    padding-left: 72px;
    padding-right: 72px;
  }
  .boxhead {
    padding-left: 72px;
    padding-right: 72px;
  }
  .column1 {
    margin-top: 10px;
    width: 60%;
    min-width: 200px;
    height: auto;
    float: left;
  }
  .column2 {
    margin-top: 10px;
    width: 30%;
    min-width: 200px;
    height: auto;
    float: right;
  }
  .grünebox {
    width: 1024px;
  }
  .headlinetrinkspiele {
    padding-left: 72px;
    padding-right: 72px;
  }
  .spacer {
    width: 50px;
    float: left;
  }
  .Testimonialdiv {
    position: absolute;
  }
}

* {
  margin: 0;
  padding: 0;
}
.anchorli {
  margin-bottom: 5px;
  font-size: 20px;
  font-family: Outfit;
}
.Anchorlinknav {
}
.AnchorlinkLPnav {
  margin-right: 50px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.avatar {
  text-align: center;
  margin: auto;
  color: #3f3f4d;
  font-size: 35px;
  width: auto;
  padding-top: 20px;
  margin-top: 0px;
}
body {
  margin: 0;
  font-family: "Outfit";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  font-size: 18px;
}
.body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.box {
  background-color: white;
  margin: auto;
  padding-top: 24px;
  border-radius: 20px;
  max-width: 1024px;
}
.boxhead {
  max-width: 1024px;
  margin: auto;
}
button {
  padding: 3px 10px;
  cursor: pointer;
  background: none;
  border: #00000000;
  color: #0f0f0f;
  font-size: 20px;
  font-weight: bold;
  font-family: "Outfit";
}
button:hover {
  text-decoration: underline;
  padding: 3px 10px;
  cursor: pointer;
  background: none;
  border: #00000000;
  color: #0f0f0f;
  font-size: 20px;
  font-weight: bold;
  font-family: "Outfit";
}
button.primary {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 50px;
  padding-right: 50px;
  font-size: 30px;
  border-radius: 20px;
  color: #ffffff;
  background-color: #3f3f4d;
  border: #0f0f0f solid 1px;
  line-height: 55px;
  min-width: 252px;
  max-width: 300px;
  width: -webkit-fill-available;
}
button.secondary {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 50px;
  padding-right: 50px;
  font-size: 20px;
  border-radius: 20px;
  text-decoration: underline;
  color: #3f3f4d;
  line-height: 30px;
  min-width: 200px;
  max-width: 250px;
  width: -webkit-fill-available;
}

.ButtonVote {
  border: #0f0f0f;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 15px;
  outline-style: solid;
  max-width: 300px;
  min-width: 300px;
}
.Never {
  background-color: rgba(255, 0, 0, 0.459);
}
.Have {
  background-color: rgba(0, 128, 0, 0.49);
}

.centertext {
  text-align: center;
}
.cleanlist {
  list-style-type: none;
}

.column {
  margin-top: 10px;
  width: fit-content;
  min-width: 200px;
  height: auto;
  float: left;
}
.ComBalkenJa {
  border-radius: 0px 20px 20px 0px;
  background-color: aquamarine;
  padding: 5px;
  margin-bottom: 5px;
  width: 100%;
}
.ComBalkenNein {
  border-radius: 0px 20px 20px 0px;
  background-color: brown;
  color: #fff;
  padding: 5px;
  margin-bottom: 5px;
  width: 100%;
}
.dropdown {
  margin-top: 10px;
  margin-bottom: 10px;
  height: auto;
}
.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.dropdowna {
  padding: 3px 10px;
  cursor: pointer;
  background: none;
  border: #00000000;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  font-family: "Outfit";
}
.dropdownerror {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 20px;
  border-radius: 20px;
  color: #2f2d2f;
  background-color: #3f3f4d;
  border: #0f0f0f solid 1px;
  line-height: 30px;
  min-width: 200px;
  max-width: 250px;
  width: -webkit-fill-available;
}
.devider {
  border: solid grey;
  border-width: 0.5px;
}
.einzelfilter {
  display: inline-flex;
  border: solid;
  margin-bottom: 10px;
  background-color: #ffffff;
  border-color: #3f3f4d;
  border-radius: 25px;
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  line-height: 30px;
  width: -webkit-fill-available;
  min-width: 252px;
  max-width: 287px;
}
.footerbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Replace with the width you want */
  height: auto;
  bottom: 0px;
  flex-shrink: 0;
  padding-top: 10px;
  background-color: #3f3f4d;
}
.footerstripes {
  flex: 1;
  height: 10vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
}
.footerbutton {
  color: #fff;
}
.fragenfilter {
  display: grid;
  justify-items: start;
  width: auto;
  min-width: 252px;
  max-width: 300px;
}
.filterspan {
  margin-right: 10px;
  font-size: 20px;
  font-family: "Outfit";
  width: -webkit-fill-available;
}
.gocard {
  margin: auto;
  font-size: 20px;
  display: grid;
  justify-items: center;
}
.grünebox {
  padding-bottom: 20px;
  align-items: center;
  margin: auto;
  min-height: 70vh;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
}
.grüneboxd {
  padding-bottom: 20px;
  align-items: center;
  width: fit-content;
  margin: auto;
  height: 20vh;
  display: flex;
}
h3 {
  width: 100%;
}
.headerbox {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: normal;
  width: 100%;
  bottom: 0px;
  flex-shrink: 0;
  background-color: #3f3f4d;
  height: auto;
}
.headlinetrinkspiele {
  max-width: 1024px;
  margin: auto;
  padding-top: 12px;
  padding-bottom: 12px;
}
html {
  margin: unset;
  padding: unset;
  min-width: unset;
  display: unset;
}
.kopftrinkspiele {
  background-color: #3f3f4d;
  width: 100%;
  color: #fff;
  height: auto;
}
ul {
  margin-left: 20px;
}
.menu {
  position: absolute;
  list-style-type: none;
  margin: 5px 0;
  padding: 0;
  background-color: #fff;
  z-index: 100;
  box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.482);
  border-radius: 0px 0px 20px 20px;
  padding-bottom: 10px;
  padding-right: 10px;
}

.menu > li {
  margin: 0;
  background-color: #ffffff;
}

.menu > li:hover {
}
.menu > li > button:hover {
  text-decoration: underline;
}

.menu > li > button {
  width: 100%;
  height: 100%;
  text-align: left;
  padding: 5px;
  margin: 0;
  font: inherit;
  cursor: pointer;
}
.menu-item {
  border-radius: 20px;
}
ol {
  margin-left: 20px;
}
.Pictureanchor {
  float: right;
  margin: auto;
  margin-bottom: 10px;
}
.PABox {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  margin-bottom: 50px;
}
:root {
  margin: 0px;
  padding: 0px;
}
.schwarzebox {
  background-color: #ffffff;
  background-size: cover;
  background-position-x: center;
  width: 100%;
  position: static;
  top: 0;
  left: 0;
}
.seopics {
  min-width: 200px;
  width: 100%;
  clear: right;
  border-radius: 20px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 86px;
  height: 34px;
}
.switch:focus {
  outline-color: #e59700;
  outline: solid;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 1;
  width: 64px;
  height: 34px;
  border-radius: 34px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #7d7d8b;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.Testimonialdiv {
  bottom: 0px;
  right: 0px;
  height: auto;
}
input:checked + .slider {
  background-color: #3f3f4d;
}

input:focus + .slider {
  outline: 1px #e59700;
}

input:focus {
  outline: 2px solid #e59700;
  border-radius: 34px;
}

input:checked + .slider:before {
  -webkit-transform: translateX(31px);
  -ms-transform: translateX(31px);
  transform: translateX(31px);
}

.row {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.Feedback {
  position: fixed;
  width: 25px;
  height: 200px;
  top: 40%;
  right: 0px;
  z-index: 1;
  border: solid 2px #0f0f0f;
  border-radius: 10px 0px 0px 10px;
  background-color: #fff;
  writing-mode: vertical-lr;
  text-orientation: upright;
}
.FeedbackB {
  padding: 0px;
  margin: 0px;
  text-decoration: none;
}
