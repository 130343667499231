.card {
  width: 253px;
  height: 363px;
  color: #ffffff;
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 40px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-description {
  font-size: 14px;
  margin-bottom: 10px;
}

.card-image {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.card-button {
  background-color: #3f3f4d;
  border: #0f0f0f solid 1px;
  color: #ffffff;
  padding: 8px 16px;
  border-radius: 15px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  height: 40px;
  width: 200px;
  margin-left: 26px;
  position: absolute;
  bottom: 45px;
}

.card-button:hover {
  background-color: #c2d5ed;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  border-top: #ffffff 70px solid;
}

#kartentext {
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 10px;
  width: auto;
  margin: 20px;
  color: #000;
  position: absolute;
  top: 40px;
}
#kartentitel {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  width: auto;
  padding: 20px;
  color: rgb(0, 0, 0);
}

.vote-container {
  margin: auto;
  display: grid;
}
